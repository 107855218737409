.root {
  overflow: hidden;
}

.z-max {
  z-index: 999999;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customScrollBar {
  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

//

.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

.ProductDetailModalPhotos .glide__bullet--active {
  @apply block;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.menuIsNew_lv1 {
  > a::before {
    content: "New!";
    position: absolute;
    top: -2px;
    right: 0;
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    font-weight: normal;
  }
}
.menuIsNew {
  > a::after {
    content: "New!";
    display: inline-block;
    padding: 0 5px;
    line-height: 15px;
    border-radius: 4px;
    background-color: #f35656;
    color: #fff;
    font-size: 10px;
    margin-left: 6px;
  }
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200/70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-6 xl:py-6 xl:px-8;
}
.nc-hero-field-focused {
  @apply shadow-2xl rounded-full dark:bg-white/5;
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}
/* 
 */
.nc-shadow-lg {
  box-shadow: 2px 8px 40px rgba(0, 0, 0, 0.08);
}
.hover\:nc-shadow-lg:hover {
  box-shadow: 2px 8px 40px rgba(0, 0, 0, 0.08);
}

.nc-shadow-sketch {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.hover\:nc-shadow-sketch:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.nc-p-l-container {
  padding-left: 16px;
  @screen sm {
    padding-left: calc((100vw + 32px - 640px) / 2);
  }
  @screen md {
    padding-left: calc((100vw + 32px - 768px) / 2);
  }
  @screen lg {
    padding-left: calc((100vw + 32px - 1024px) / 2);
  }
  @screen xl {
    padding-left: calc((100vw + 80px - 1280px) / 2);
  }
  @screen 2xl {
    padding-left: calc((100vw + 256px - 1536px) / 2);
  }
}

.nc-p-r-container {
  padding-right: 16px;
  @screen sm {
    padding-right: calc((100vw + 32px - 640px) / 2);
  }
  @screen md {
    padding-right: calc((100vw + 32px - 768px) / 2);
  }
  @screen lg {
    padding-right: calc((100vw + 32px - 1024px) / 2);
  }
  @screen xl {
    padding-right: calc((100vw + 80px - 1280px) / 2);
  }
  @screen 2xl {
    padding-right: calc((100vw + 256px - 1536px) / 2);
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap--old {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

.listingSectionSidebar__wrap {
  @apply w-full flex flex-col rounded-2xl border border-neutral-200 dark:border-neutral-700 space-y-6 space-y-8 pb-10 px-0 p-4 xl:p-8;
}


.listingSection__wrap {
  @apply w-full flex flex-col border-b border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-14;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.nc-product-notify-animation {
  animation-name: ncSlideLeft;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

.nc-product-notify-animation-out {
  animation-name: ncSlideRight;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}

@keyframes ncSlideLeft {
  from {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes ncSlideRight {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(200px);
    opacity: 0;
  }
}

@keyframes CardLarge1ExampleRight {
  from {
    transform: scale(1.05) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes CardLarge1ExampleLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes SectionHero2ItemLeft {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes SectionHero2ItemDot {
  from {
    width: 30px;
    opacity: 0;
  }
  to {
    width: 80px;
    opacity: 1;
  }
}

/* == */
.nc-SectionHero2Item--animation {
  .nc-SectionHero2Item__dot {
    animation-name: SectionHero2ItemDot;
    animation-duration: 150ms;
    animation-timing-function: linear;
  }

  .nc-SectionHero2Item__image {
    animation-name: CardLarge1ExampleRight;
    animation-duration: 650ms;
    animation-timing-function: ease-in-out;
  }

  .nc-SectionHero2Item__left {
    animation-name: SectionHero2ItemLeft;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
  }

  .nc-SectionHero2Item__button {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
  }
  .nc-SectionHero2Item__heading {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 600ms;
    animation-timing-function: ease-in-out;
  }
  .nc-SectionHero2Item__subheading {
    animation-name: CardLarge1ExampleLeft;
    animation-timing-function: ease-in-out;
    animation-duration: 400ms;
  }
}
